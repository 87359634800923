import React from 'react';
import fire from '../../config/shit';
import Firebase from 'firebase'
import CHeader from '../../components/ctemplatecomponents/CHeader';
import CSPAHeader from '../../components/ctemplatecomponents/CSPAHeader';




class CCuenta extends React.Component {
    constructor(props) {
        super(props)
        this.state = ({
            user: null,
            email:null
        });
        this.authListener = this.authListener.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        }

        componentDidMount() {
            this.authListener()
   
        }
    
        authListener() {
            fire.auth().onAuthStateChanged((user) => {
                
                if (user) {
                    this.setState({ user });
                    localStorage.setItem('user',user.uid);

                    var user = Firebase.auth().currentUser;
                    var name, email, photoUrl, uid, emailVerified;

                    if (user != null) {
                    name = user.displayName;
                    email = user.email;
                    
                    photoUrl = user.photoURL;
                    emailVerified = user.emailVerified;
                    uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                                    // this value to authenticate with your backend server, if
                                    // you have one. Use User.getToken() instead.
                   
                   this.setState({email: email})

                   
                }
                    
                } else {
                    this.setState({ user: null });
                    localStorage.removeItem('user');
                }
            });


            
        }

        resetPassword(){

            var emailAddress = this.state.email;

            fire.auth().sendPasswordResetEmail(emailAddress).then(function() {
              console.log('email sent!');
            }).catch(function(error) {
              // An error happened.
            });
        }

    render () {

        if (this.state.user != null ) {

            return (
                <div>
                <CSPAHeader auth="true" />
                    <div className="Hero">
                        <div className="HeroGroup">
                            <div>
                                <h1>User</h1>
                                <h1>{this.state.email}</h1>
                                
                            </div>
                        </div>
                    </div>
                </div>
            )

        } else if (this.state.user == null) {

            return (
                <div>
                <CSPAHeader auth="true" />
                    <div className="Hero">
                        <div className="HeroGroup">
                            <div>
                            <h1>User Not Auth</h1>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default CCuenta;