import React from 'react'
import Link, { replace } from 'gatsby-link'

import logo from '../../Images/logo.png';
import fire from '../../config/shit';

import './CHeader.css';


class CHeader extends React.Component {
    constructor(props) {
        super(props)

        this.handleLogOut = this.handleLogOut.bind(this);
        this.state = {
            hasScrolled: false,
            cont: "",
            
        }

    }

    componentDidMount(){
        
        console.log(this.props.lang)
    
        window.addEventListener('scroll', this.handleScroll)
        
    }

    handleScroll = (event) => {

        const scrollTop = window.pageYOffset

        if (scrollTop > 50) {
            this.setState({ hasScrolled: true})

        } else {
            this.setState({ hasScrolled: false})
        }

    }

    handleLogOut() {
        fire.auth().signOut().then(u => {
            replace('/ctemplate')
        });
        
    }



    render () {
        

        if(this.props.auth == "true" && this.props.lang == "Spanish") {

            return (

               <div className={this.state.hasScrolled ? 'Header HeaderScrolled' : 'Header'}>
                    <div className="HeaderGroup">
                        <Link to="/ctemplate"><img className="HeaderLogo" src={logo} width="30"></img></Link>
                        <Link to="/ctemplate">Bienvenido</Link>
                        <Link to="/ctemplate/herramientas">Herramientas</Link>
                        <Link to="/ctemplate/Account">Cuenta</Link>
                        <Link to="/ctemplate"><button className="HeaderButton" onClick={this.handleLogOut}>Cerrar Sesion</button></Link>
                    </div>
                </div>
            );

        } else if (this.props.auth == "true" && this.props.lang == "English") {

            return (

                <div className={this.state.hasScrolled ? 'Header HeaderScrolled' : 'Header'}>
                     <div className="HeaderGroup">
                         <Link to="/ctemplate"><img className="HeaderLogo" src={logo} width="30"></img></Link>
                         <Link to="/ctemplate">Welcome</Link>
                         <Link to="/ctemplate/Tools">Tools</Link>
                         <Link to="/ctemplate/Account">Account</Link>
                         <Link to="/ctemplate"><button className="HeaderButton" onClick={this.handleLogOut}>Log Out</button></Link>
                     </div>
                 </div>
             );


        } else if (this.props.auth == "false" ) {

            return (

                <div className={this.state.hasScrolled ? 'Header HeaderScrolled' : 'Header'}>
                     <div className="HeaderGroup">
                     <Link to="/"><img className="HeaderLogo" src={logo} width="30"/></Link>
                     <Link to="/">WELCOME</Link>
                   
 
                     </div>
                 </div>
             );


        } else {

            return ( null)
        }
        
    }
}

export default CHeader;